/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import FormState from "../containers/FormState";
import ButtonBase from "gatsby-theme-firebase/src/components/Button";

const Button: React.FunctionComponent<{
  active: boolean;
  onClick: () => void;
}> = ({ active, ...props }) => (
  <ButtonBase
    sx={{
      width: "100%",
      bg: "white",
      color: active ? "primary" : "border",
      border : "1px solid",
    
    }}
    {...props}
  ></ButtonBase>
);

const Nav: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = ({
  ...restProps
}) => {
  const { formType, setFormType } = FormState.useContainer();

  return (
    <div sx={{ bg:"white", display: "flex", width: "100%" }} {...restProps}>
      <Button
        active={formType === "sso"}
        sx={{
        
          marginLeft: 1,
        }}
        onClick={() => {
          setFormType("sso");
        }}
      >
        Single Sign On
      </Button>

      <Button
        active={formType === "login"}
        sx={{
        
          marginLeft: 1,
          marginRight: 1,
        }}
        onClick={() => {
          setFormType("login");
        }}
      >
        Login
      </Button>
    
      <Button
        active={formType === "signup"}
        sx={{
         
          marginRight: 1,
        }}
        onClick={() => {
          setFormType("signup");
        }}
      >
        Register
      </Button>
    </div>
  );
};

export default Nav;
