/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import * as React from "react";
import useFirebaseConfig from "gatsby-theme-firebase/src/hooks/useFirebaseConfig";
import FormState from "../containers/FormState";
import Form from "gatsby-theme-firebase/src/components/FormBase";
import SocialLogins from "gatsby-theme-firebase/src/components/SocialLogins";

const SSOForm: React.FunctionComponent<{
  onSuccess?: (user?: firebase.auth.UserCredential) => void;
}> = ({ onSuccess = () => {}, ...restProps }) => {
  const { socialLogins } = useFirebaseConfig();
  const { setFormType, setErrorMessage } = FormState.useContainer();
   

  return (
    <Form
      {...restProps}
     
    >
      {socialLogins.length > 0 && (
        <div>
          <SocialLogins onSuccess={onSuccess} />
          <Styled.hr sx={{ my: 3 }} />
        </div>
      )}

       
    </Form>
  );
};

export default SSOForm;
